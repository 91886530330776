import { LocalstorageService } from './services/localstorage.service';
import { OtherService } from 'src/app/student/services/other.service';
import { TimeoutService } from './services/timeout.service';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { AuthService } from './services/auth.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Carousel } from 'primeng/carousel';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertSnackbarComponent } from './shared/snackbar/alert-snackbar/alert-snackbar.component';
import { Subscription } from 'rxjs';
import { PsychometricService } from '../app/assessments/services/psychometric.service';
import { ResolveEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
declare var gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('cursoricon') cursoricon: ElementRef;
  changeImg = false;
  changeImg1 = false;
  changeImg2 = false;
  changeImg3 = false;
  changeImg4 = false;
  changeImg5 = false;
  changeImg6 = false;
  titleVal = '';
  subscription:Subscription;
  public isSmallDevice: boolean = false;
  public isLargeDevice: boolean = false;
  questions : any = [{imgPath:''},{imgPath:''},{imgPath:''},{imgPath:''},{imgPath:''},{imgPath:''}];
  private routerSubscription: Subscription;

  constructor(public auth: AuthService,
    private el: ElementRef,
    private _otherService:OtherService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,

    private router:Router,
    private _timeoutService: TimeoutService,
    public breakpointObserver: BreakpointObserver,
    public _psychometricService:PsychometricService,
    private _localStorage:LocalstorageService,
    private _snackBar: MatSnackBar,) {
     Carousel.prototype.onTouchMove = () => { };
     this.subscription = this._psychometricService.SharingAnimationData.subscribe((res: any) => {
        this._psychometricService.showOverlayInterest = res.showOverlay;
        this.titleVal = res.value;
        this.resteData();
        this.startTour();
      })
    this.breakpointObserver.observe([
      Breakpoints.HandsetLandscape
    ]).subscribe(result => {
      if (result.matches) {
        // this._snackBar.openFromComponent(AlertSnackbarComponent, {
        //   verticalPosition: 'top',
        //   horizontalPosition: 'center',
        //   panelClass: ['landscape-snackbar']
        // });
      }
      else {
        this._snackBar.dismiss();
      }
    });

    iconRegistry.addSvgIcon(
      'academia',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/academia.svg'));

      iconRegistry.addSvgIcon(
        'student-nav',
        sanitizer.bypassSecurityTrustResourceUrl('assets/svg/student-nav.svg'));

    iconRegistry.addSvgIcon(
      'student',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/student.svg'));

    iconRegistry.addSvgIcon(
      'counsellor',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/counsellor.svg'));

    iconRegistry.addSvgIcon(
      'enterprises',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/enterprises.svg'));

    iconRegistry.addSvgIcon(
      'parents',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/parents.svg'));


    iconRegistry.addSvgIcon(
      'test',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navbar-icons/assessment.svg'));

    iconRegistry.addSvgIcon(
      'binoculars',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/binoculars.svg'));

    iconRegistry.addSvgIcon(
      'dashboard',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/dashboard.svg'));

    iconRegistry.addSvgIcon(
      'profile',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/test.svg'));

    iconRegistry.addSvgIcon(
      'careers',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navbar-icons/careers.svg'));

    iconRegistry.addSvgIcon(
      'careers2',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/other/careers.svg'));

    iconRegistry.addSvgIcon(
      'stream',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/other/stream.svg'));

    iconRegistry.addSvgIcon(
      'connections',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navbar-icons/connections.svg'));

    iconRegistry.addSvgIcon(
      'settings',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navbar-icons/settings.svg'));
      
      iconRegistry.addSvgIcon(
        'contact-support',
        sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navbar-icons/contact-support.svg'));
  

    iconRegistry.addSvgIcon(
      'logout',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/logout.svg'));

    iconRegistry.addSvgIcon(
      'avatar',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navbar-icons/profile.svg'));

      iconRegistry.addSvgIcon(
        'feedback',
        sanitizer.bypassSecurityTrustResourceUrl('assets/svg/feedback/feedback_regular.svg'));

    iconRegistry.addSvgIcon(
      'home',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navbar-icons/home.svg'));

      iconRegistry.addSvgIcon(
        'graph_loc',
        sanitizer.bypassSecurityTrustResourceUrl('assets/svg/location.svg'));

      iconRegistry.addSvgIcon(
        'menu',
        sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navbar-icons/menu.svg'));

    iconRegistry.addSvgIcon(
      'sub-menu',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/sub-menu.svg'));

    iconRegistry.addSvgIcon(
      'basic',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/basic.svg'));

    iconRegistry.addSvgIcon(
      'family',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/family_profile.svg'));

    iconRegistry.addSvgIcon(
      'academic',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/academic.svg'));

    iconRegistry.addSvgIcon(
      'calendar',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/calendar.svg'));

    iconRegistry.addSvgIcon(
      'education',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/education.svg'));

    iconRegistry.addSvgIcon(
      'progress',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/progress.svg'));

    iconRegistry.addSvgIcon(
      'challenge',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/challenge.svg'));

    iconRegistry.addSvgIcon(
      'logout',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/other/navbar/logout.svg'));

    iconRegistry.addSvgIcon(
      'security',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/other/navbar/security.svg'));

    iconRegistry.addSvgIcon(
      'help',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/other/navbar/help.svg'));

    iconRegistry.addSvgIcon(
      'read_enable',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/Languages/read_enable.svg'));

    iconRegistry.addSvgIcon(
      'write_enable',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/Languages/write_enable.svg'));

    iconRegistry.addSvgIcon(
      'speak_enable',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/Languages/speak_enable.svg'));

    iconRegistry.addSvgIcon(
      'read',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/Languages/read.svg'));

    iconRegistry.addSvgIcon(
      'write',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/Languages/write.svg'));

    iconRegistry.addSvgIcon(
      'speak',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/Languages/speak.svg'));

    iconRegistry.addSvgIcon(
      'ellipsis',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ellipsis.svg'));

    iconRegistry.addSvgIcon('understand',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/Languages/understand.svg'));

    iconRegistry.addSvgIcon(
      'understand_enable',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/Languages/understand_enable.svg'));


    iconRegistry.addSvgIcon(
      'get_toKnow_proteen',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/other/other_tab/get_toKnow_proteen.svg'));

    iconRegistry.addSvgIcon(
      'health_concerns',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/other/other_tab/health_concerns.svg'));

    iconRegistry.addSvgIcon(
      'reason_to_join',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/other/other_tab/reason_to_join.svg'));

    iconRegistry.addSvgIcon(
      'learning_disability',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/other/other_tab/learning_disability.svg'));

    iconRegistry.addSvgIcon(
      'undergoing_treatment',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/other/other_tab/undergoing_treatment.svg'));

    iconRegistry.addSvgIcon(
      'progress-arrow',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/progress-arrow.svg'));

    iconRegistry.addSvgIcon(
      'id-card',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/id-card.svg'));

    iconRegistry.addSvgIcon(
      'familyIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/familyIcon.svg'));

    iconRegistry.addSvgIcon(
      'basic',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/basic_orange.svg'));

    iconRegistry.addSvgIcon(
      'basic_white',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/basic_white.svg'));


    iconRegistry.addSvgIcon(
      'personal',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/personal_orange.svg'));

    iconRegistry.addSvgIcon(
      'personal_white',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/personal_white.svg'));

    iconRegistry.addSvgIcon(
      'family',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/family_orange.svg'));

    iconRegistry.addSvgIcon(
      'family_white',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/family_white.svg'));


    iconRegistry.addSvgIcon(
      'education',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/education_orange.svg'));

    iconRegistry.addSvgIcon(
      'education_white',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/education_white.svg'));


    iconRegistry.addSvgIcon(
      'other',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/others_orange.svg'));


    iconRegistry.addSvgIcon(
      'other_white',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/others_white.svg'));

    iconRegistry.addSvgIcon(
      'Icon_feather_edit',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/Icon_feather_edit.svg'));

    iconRegistry.addSvgIcon(
      'edit_orange',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/edit_orange.svg'));

    iconRegistry.addSvgIcon(
      'close_orange',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/close_organge.svg'));

    iconRegistry.addSvgIcon(
      'family_orange_5',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/family_orange_5.svg'));

    iconRegistry.addSvgIcon(
      'family_white_5',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/family_white_5.svg'));

    iconRegistry.addSvgIcon(
      'pointsBadge',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/EarnPoints.svg'));

    iconRegistry.addSvgIcon(
      'badgeCompletion',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/badge-completion.svg'));

    iconRegistry.addSvgIcon(
      'like',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/like.svg'));

    iconRegistry.addSvgIcon(
      'notification',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/notification_new.svg'));


    iconRegistry.addSvgIcon(
      'speak',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/Languages/speak.svg'));

    iconRegistry.addSvgIcon(
      'ellipsis',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ellipsis.svg'));

    iconRegistry.addSvgIcon('understand',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/Languages/understand.svg'));

    iconRegistry.addSvgIcon(
      'understand_enable',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/Languages/understand_enable.svg'));


    iconRegistry.addSvgIcon(
      'get_toKnow_proteen',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/other/other_tab/get_toKnow_proteen.svg'));

    iconRegistry.addSvgIcon(
      'health_concerns',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/other/other_tab/health_concerns.svg'));

    iconRegistry.addSvgIcon(
      'reason_to_join',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/other/other_tab/reason_to_join.svg'));

    iconRegistry.addSvgIcon(
      'learning_disability',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/other/other_tab/learning_disability.svg'));

    iconRegistry.addSvgIcon(
      'undergoing_treatment',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/other/other_tab/undergoing_treatment.svg'));

    iconRegistry.addSvgIcon(
      'progress-arrow',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/progress-arrow.svg'));

    iconRegistry.addSvgIcon(
      'id-card',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/id-card.svg'));

    iconRegistry.addSvgIcon(
      'familyIcon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/familyIcon.svg'));

    iconRegistry.addSvgIcon(
      'basic',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/basic_orange.svg'));

    iconRegistry.addSvgIcon(
      'basic_white',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/basic_white.svg'));

    iconRegistry.addSvgIcon(
      'personal',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/personal_orange.svg'));

    iconRegistry.addSvgIcon(
      'personal_white',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/personal_white.svg'));

    iconRegistry.addSvgIcon(
      'family',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/family_orange.svg'));

    iconRegistry.addSvgIcon(
      'family_white',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/family_white.svg'));


    iconRegistry.addSvgIcon(
      'education',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/education_orange.svg'));

    iconRegistry.addSvgIcon(
      'education_white',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/education_white.svg'));


    iconRegistry.addSvgIcon(
      'other',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/others_orange.svg'));


    iconRegistry.addSvgIcon(
      'other_white',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/others_white.svg'));

    iconRegistry.addSvgIcon(
      'edit_orange',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/edit_orange.svg'));

    iconRegistry.addSvgIcon(
      'close_orange',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/close_organge.svg'));

    iconRegistry.addSvgIcon(
      'family_orange_5',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/family_orange_5.svg'));

    iconRegistry.addSvgIcon(
      'family_white_5',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/family_white_5.svg'));

    iconRegistry.addSvgIcon(
      'pointsBadge',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/EarnPoints.svg'));

    iconRegistry.addSvgIcon(
      'pointsBadgeMobile',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/badge-mobile.svg'));

    iconRegistry.addSvgIcon(
      'badgeCompletion',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/badge-completion.svg'));

    iconRegistry.addSvgIcon(
      'like',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/like.svg'));

    iconRegistry.addSvgIcon(
      'notification',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/notification_new.svg'));

    iconRegistry.addSvgIcon(
      'basic-blue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/circularTab/basic-blue.svg'));

    iconRegistry.addSvgIcon(
      'basic-orange',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/profile-tab/circularTab/basic-orange.svg'));

    iconRegistry.addSvgIcon(
      'info',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/info_new.svg'));

    iconRegistry.addSvgIcon(
      'proteen-logo',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/logo/Proteen-Logo-Black.svg'));

    iconRegistry.addSvgIcon(
      'back-arrow',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/other/back-arrow.svg'));

      iconRegistry.addSvgIcon(
        'back-icon',
        sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navbar/back-arrow.svg'));

    iconRegistry.addSvgIcon(
      'notification',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navbar-icons/sidebar/notification.svg'));

    iconRegistry.addSvgIcon(
        'pricing',
        sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navbar-icons/pricing_nav.svg'));

        iconRegistry.addSvgIcon(
          'pricing3',
          sanitizer.bypassSecurityTrustResourceUrl('assets/pricing-icon.svg')
        );

        iconRegistry.addSvgIcon(
          'pricing1',
          sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navbar-icons/pricing.svg'));
    iconRegistry.addSvgIcon(
      'clearcache',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/clearcache.svg')
    );
    iconRegistry.addSvgIcon(
      'exportdata',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/exportd.svg')
    );
    iconRegistry.addSvgIcon(
      'exportemail',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/exportemail.svg')
    );
    iconRegistry.addSvgIcon(
      'interest',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/assessments/interest.svg'));

    iconRegistry.addSvgIcon(
      'personality',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/assessments/personality.svg'));

    iconRegistry.addSvgIcon(
      'aptitude',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/assessments/aptitude.svg'));

    iconRegistry.addSvgIcon(
      'multiple-intelligience',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/assessments/multiple-intelligience.svg'));

      iconRegistry.addSvgIcon(
        'interest-icon',
        sanitizer.bypassSecurityTrustResourceUrl('assets/svg/assessments/interest-icon.svg'));

    iconRegistry.addSvgIcon(
      'interestarrows',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/assessments/Interest_Arrows.svg'));

    iconRegistry.addSvgIcon(
      'infoicon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/assessments/info.svg'));
    iconRegistry.addSvgIcon(
      'student-g',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/grey-icon/student-g.svg'));
    iconRegistry.addSvgIcon(
      'school-g',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/grey-icon/school-g.svg'));

    iconRegistry.addSvgIcon(
      'parent-g',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/grey-icon/parent-g.svg'));
    iconRegistry.addSvgIcon(
      'mentor-g',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/grey-icon/mentor-g.svg'));
    iconRegistry.addSvgIcon(
      'enterprise-g',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/grey-icon/enterprise-g.svg'));
    iconRegistry.addSvgIcon(
      'coupon-g',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/grey-icon/coupon-g.svg'));

    iconRegistry.addSvgIcon(
      'parent-o',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/orange-icon/parent-o.svg'));
    iconRegistry.addSvgIcon(
      'mentor-o',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/orange-icon/mentor-o.svg'));
    iconRegistry.addSvgIcon(
      'student-o',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/orange-icon/student-o.svg'));
    iconRegistry.addSvgIcon(
      'school-o',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/orange-icon/school-o.svg'));
    iconRegistry.addSvgIcon(
      'enterprise-o',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/orange-icon/enterprise-o.svg'));
    iconRegistry.addSvgIcon(
      'coupon-o',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/orange-icon/coupon-o.svg'));
    iconRegistry.addSvgIcon(
      'Path-o',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/orange-icon/Path-o.svg'));
    iconRegistry.addSvgIcon(
      'delete-or',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/orange-icon/delete-g.svg'));
    iconRegistry.addSvgIcon(
      'edit-or',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/orange-icon/edit (5).svg'));
    iconRegistry.addSvgIcon(
      'interest-white',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/interest-white.svg'));
    iconRegistry.addSvgIcon(
      'interest-white-activated',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/interest-o.svg'));
    iconRegistry.addSvgIcon(
      'personality-white-activated',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/personality.svg'));
    iconRegistry.addSvgIcon(
      'personality-white',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/personality-white.svg'));
    iconRegistry.addSvgIcon(
      'aptitude-white-activated',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/aptitude.svg'));
    iconRegistry.addSvgIcon(
      'aptitude-white',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/aptitude-white.svg'));
    iconRegistry.addSvgIcon(
      'multiple-intelligence-white-activated',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/multiple-intelligence.svg'));
    iconRegistry.addSvgIcon(
      'multiple-intelligence-white',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/multiple-intelligence-white.svg'));
    iconRegistry.addSvgIcon(
      'lock',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lock.svg'));
    iconRegistry.addSvgIcon(
      'unlock',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/unlock.svg'));
    iconRegistry.addSvgIcon(
      'import',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/imports.svg'));
    iconRegistry.addSvgIcon(
      'export',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/export.svg'));
    iconRegistry.addSvgIcon(
      'filter',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/filters.svg'));
    iconRegistry.addSvgIcon(
      'plus',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/plus.svg'));
    iconRegistry.addSvgIcon(
      'edit',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/edit.svg'));
    iconRegistry.addSvgIcon(
      'trash',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/trash.svg'));
    iconRegistry.addSvgIcon(
      'instructions',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/assessments/test-instructions.svg'));
    iconRegistry.addSvgIcon(
      'attachment',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/attachment.svg'));
    iconRegistry.addSvgIcon(
      'Self-Awareness',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/upgrade/self-awareness.svg'));
    iconRegistry.addSvgIcon(
      'Self-Awareness-activated',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/upgrade/self-awareness-activated.svg'));
      iconRegistry.addSvgIcon(
        'Self-Awareness-activatedd',
        sanitizer.bypassSecurityTrustResourceUrl('assets/svg/upgrade/self-awareness-activatedd.svg'));
    iconRegistry.addSvgIcon(
      'Experience',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/upgrade/Experience.svg'));
    iconRegistry.addSvgIcon(
      'Experience-activated',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/upgrade/Experience-activated.svg'));
      iconRegistry.addSvgIcon(
        'Experience-activatedd',
        sanitizer.bypassSecurityTrustResourceUrl('assets/svg/upgrade/Experience-activatedd.svg'));
    iconRegistry.addSvgIcon(
      'recommendation',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/upgrade/recommendation.svg'));
    iconRegistry.addSvgIcon(
      'admin-user',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/dashboard/user.svg'));
    iconRegistry.addSvgIcon(
      'admin-phone',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/dashboard/phone.svg'));
    iconRegistry.addSvgIcon(
      'admin-role',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Admin/dashboard/admin.svg'));

    iconRegistry.addSvgIcon(
      'recommendation-activated',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/upgrade/recommendation-activated.svg'));
      iconRegistry.addSvgIcon(
        'recommendation-activatedd',
        sanitizer.bypassSecurityTrustResourceUrl('assets/svg/upgrade/recommendation-activatedd.svg'));
      iconRegistry.addSvgIcon(
        'blue-tick',
        sanitizer.bypassSecurityTrustResourceUrl('assets/svg/assessments/Blue_Tick.svg'));
        iconRegistry.addSvgIcon(
        'orange-tick',
          sanitizer.bypassSecurityTrustResourceUrl('assets/svg/assessments/orange-tick.svg'));

        iconRegistry.addSvgIcon(
          'white-tick',
          sanitizer.bypassSecurityTrustResourceUrl('assets/svg/assessments/white-tick.svg'));
        iconRegistry.addSvgIcon(
          'career-quiz',
          sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Careers/career-quiz/careerQuiz.svg'));
        iconRegistry.addSvgIcon(
          'progress-landing',
          sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Careers/career-quiz/progress-landing.svg'));

          iconRegistry.addSvgIcon(
            'quizLock',
            sanitizer.bypassSecurityTrustResourceUrl('assets/svg/Careers/career-quiz/lock.svg'));

          iconRegistry.addSvgIcon(
            'Report-Regular',
            sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navbar-icons/Report-Regular.svg'));
          iconRegistry.addSvgIcon(
            'Report-Filled',
            sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navbar-icons/Report-Filled.svg'));
            iconRegistry.addSvgIcon(
              'activity-regular',
              sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navbar-icons/activity-regular.svg'));
              iconRegistry.addSvgIcon(
                'activity-solid',
                sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navbar-icons/activity-solid.svg'));
  }



  ngOnInit() {
     this.fetchEntityId()
     //Responsive UI
     this.breakpointObserver
     .observe([Breakpoints.Small, Breakpoints.XSmall])
     .subscribe((state: BreakpointState) => {
       if (state.matches) {
         this.isSmallDevice = true;
         this.isLargeDevice = false;
       }
     });
     this.breakpointObserver
     .observe([Breakpoints.Tablet])
     .subscribe((state: BreakpointState) => {
       if (state.matches) {
         this.isSmallDevice = true;
         this.isLargeDevice = false;
       }
     });
     this.breakpointObserver
     .observe([Breakpoints.Large, Breakpoints.XLarge])
     .subscribe((state: BreakpointState) => {
       if (state.matches) {
         this.isLargeDevice = true;
         this.isSmallDevice = false;
       }
     });
     /// tab switch logout 
     window.addEventListener('storage', (event) => {
      console.log('event33333333333333333333333', event)
     if (event.storageArea == localStorage) {
       console.log('localStorage----------', localStorage)
          let token = localStorage.getItem('token');
          if(token == undefined) { 
            // Perform logout
            //Navigate to login/home
             this.router.navigate(['/login']); 
          }
     }
 });

    //Google Analytics init
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof ResolveEnd))
      .subscribe((event: ResolveEnd) => {
        gtag('config', environment.googleAnalyticsID, {'page_path': event.urlAfterRedirects});
        this.auth.getAttributes().then(data => {})
        .catch(error => {
          if(error.code == 'UserNotFoundException') {
            this.router.navigate(['/proteen/logout'])
          }
        })
    });

    this.auth.getUserDetails().then(data => {
      this._timeoutService.initTimer()
    }).catch(error => { })
  }

  fetchEntityId() {
    let entityInfo = this._localStorage.get('entity_id')
    if(entityInfo) {
      environment.entity_id = entityInfo.id
      environment.domain = entityInfo.domain_name
    }
    
    this._otherService.getEntityInfo(environment.entity_name).subscribe((entityInfo) => {
      console.log(entityInfo)
      environment.entity_id = entityInfo[0].id
      environment.domain = entityInfo[0].domain_name
      this._localStorage.set('entity_id',entityInfo[0])
    }, error => console.log(error))
  }

  timoutVar1 : any;
  timoutVar2 : any;
  timoutVar3 : any;
  timoutVar4 : any;
  timoutVar5 : any;
  timoutVar6 : any;
  timoutVar7 : any;
  timoutVar8 : any;

  startTour(){


    if(this.isSmallDevice){
      this.timoutVar1 = setTimeout(() => {
        //First transition
        this.cursoricon.nativeElement.className = 'firstTrans';

        this.timoutVar2 = setTimeout(() => {
          this.cursoricon.nativeElement.style.animation = "ripple-effect 1s ease";
          this.changeImg = true;
          this.showNextRepeatBtn = false; // Reset to next btn when repeate flow start
        }, 1000);
        //Second transition
        this.timoutVar3 = setTimeout(() => {
          this.cursoricon.nativeElement.className = 'secondTrans';
          this.el.nativeElement.querySelector('.secondTrans').scrollIntoView();

          this.timoutVar4 = setTimeout(() => {
            this.cursoricon.nativeElement.style.animation = "ripple-effect1 1s ease";
            this.changeImg1 = true;
          }, 1000);
           //Third transition
           this.timoutVar5 = setTimeout(() => {
            this.cursoricon.nativeElement.className = 'thirdTrans';
            this.el.nativeElement.querySelector('.thirdTrans').scrollIntoView();

            this.timoutVar6 = setTimeout(() => {
                this.cursoricon.nativeElement.style.animation = "ripple-effect2 1s ease";
                this.changeImg2 = true;
            }, 1000);

              //Third transition
           this.timoutVar1 = setTimeout(() => {
            this.cursoricon.nativeElement.className = 'fourthTrans';
            this.el.nativeElement.querySelector('.fourthTrans').scrollIntoView();

            this.timoutVar2 = setTimeout(() => {
                this.cursoricon.nativeElement.style.animation = "ripple-effect2 1s ease";
                this.changeImg4 = true;
            }, 1000);

              //fifth transition
           this.timoutVar5 = setTimeout(() => {
            this.cursoricon.nativeElement.className = 'fithTrans';
            this.el.nativeElement.querySelector('.fithTrans').scrollIntoView();

            this.timoutVar6 = setTimeout(() => {
                this.cursoricon.nativeElement.style.animation = "ripple-effect2 1s ease";
                this.changeImg5 = true;
            }, 1000);

      //six transition
      this.timoutVar5 = setTimeout(() => {
        this.cursoricon.nativeElement.className = 'sixTrans';
        this.el.nativeElement.querySelector('.sixTrans').scrollIntoView();

        this.timoutVar6 = setTimeout(() => {
            this.cursoricon.nativeElement.style.animation = "ripple-effect2 1s ease";
            this.changeImg6 = false;
        }, 1000);
            //fo

             //Fourth transition

            //  setTimeout(() => {
            //   this.cursoricon.nativeElement.className = 'fourthTrans';
            //   this.el.nativeElement.querySelector('.fourthTrans').scrollIntoView();

            //   // document.querySelectorAll('.displayed.mainQ')[1].scrollIntoView();

            //   setTimeout(() => {
            //     this.cursoricon.nativeElement.style.animation = "ripple-effect3 1s ease";
            //     this.changeImg4 = true;
            //   }, 1000);

            //   setTimeout(() => {
            //     this.cursoricon.nativeElement.className = 'fithTrans';
            //     this.el.nativeElement.querySelector('.fithTrans').scrollIntoView();

            //     setTimeout(() => {
            //         this.cursoricon.nativeElement.style.animation = "ripple-effect2 1s ease";
            //         this.changeImg5 = true;
            //     }, 1000);

            //   setTimeout(() => {
            //     this.cursoricon.nativeElement.className = 'sixTrans';
            //     this.el.nativeElement.querySelector('.sixTrans').scrollIntoView();

            //     setTimeout(() => {
            //         this.cursoricon.nativeElement.style.animation = "ripple-effect2 1s ease";
            //         this.changeImg6 = true;
            //     }, 1000);
           this.timoutVar7 = setTimeout(() => {
            // this.cursoricon.nativeElement.className = 'fourthTrans';
            // this.el.nativeElement.querySelector('.fourthTrans').scrollIntoView();

            this.timoutVar8 = setTimeout(() => {
                this.cursoricon.nativeElement.style.animation = "ripple-effect3 1s ease";
                this.changeImg = false;
                this.changeImg1 = false;
                this.changeImg2 = false;
                this.changeImg4 = false;
                this.changeImg5 = false;
                this.changeImg3 = false;
                this.changeImg6 = false;


                this.showSkipCloseBtn = true;
                this.RepeatClick();
            }, 1000);
          }, 2500);
        }, 2500);
         }, 2500);
        }, 2500);
        }, 2500);
      }, 2500);

      }, 2500);
    }
    if(this.isLargeDevice){
      this.timoutVar1 = setTimeout(() => {
        //First transition
        this.cursoricon.nativeElement.className = 'firstTrans';

        this.timoutVar2 = setTimeout(() => {
          this.cursoricon.nativeElement.style.animation = "ripple-effect 1s ease";
          this.changeImg = true;
          this.showNextRepeatBtn = false; // Reset to next btn when repeate flow start
        }, 1000);
        //Second transition
        this.timoutVar3 = setTimeout(() => {
          this.cursoricon.nativeElement.className = 'secondTrans';
          this.el.nativeElement.querySelector('.secondTrans').scrollIntoView();

          this.timoutVar4 = setTimeout(() => {
            this.cursoricon.nativeElement.style.animation = "ripple-effect1 1s ease";
            this.changeImg1 = true;
          }, 1000);
           //Third transition
           this.timoutVar5 = setTimeout(() => {
            this.cursoricon.nativeElement.className = 'thirdTrans';
            this.el.nativeElement.querySelector('.thirdTrans').scrollIntoView();

            this.timoutVar6 = setTimeout(() => {
                this.cursoricon.nativeElement.style.animation = "ripple-effect2 1s ease";
                this.changeImg2 = true;
            }, 1000);
             //Fourth transition
           this.timoutVar7 = setTimeout(() => {
            this.cursoricon.nativeElement.className = 'fourthTrans';
            this.el.nativeElement.querySelector('.fourthTrans').scrollIntoView();

            this.timoutVar8 = setTimeout(() => {
                this.cursoricon.nativeElement.style.animation = "ripple-effect3 1s ease";
                this.changeImg = false;
                this.changeImg1 = false;
                this.changeImg2 = false;
                this.showSkipCloseBtn = true;
                this.NextClick();
            }, 1000);
          }, 2500);
          }, 2500);
        }, 2500);
      }, 2500);
    }


  }

  startTour1(){

    setTimeout(() => {
      //First transition
      // this.el.nativeElement.querySelector('.firstTrans1').scrollIntoView();

      this.timoutVar2 = setTimeout(() => {
        this.cursoricon.nativeElement.style.animation = "ripple-new 1s ease";
        this.changeImg3 = true;
        this.showNextRepeatBtn = true; //reset to repeat btn when next btn flow gets completed
      }, 1000);
      //Second transition
      setTimeout(() => {
        this.cursoricon.nativeElement.className = 'secondTrans1';
        //document.querySelectorAll('.displayed.mainQ')[1].scrollIntoView();

        setTimeout(() => {
          this.cursoricon.nativeElement.style.animation = "ripple-new1 1s ease";
          this.changeImg4 = true;
        }, 1000);
         //Third transition
         setTimeout(() => {
          this.cursoricon.nativeElement.className = 'thirdTrans1';
          this.el.nativeElement.querySelector('.thirdTrans1').scrollIntoView();
          setTimeout(() => {
              this.cursoricon.nativeElement.style.animation = "ripple-new2 1s ease";
              this.changeImg5 = true;
          }, 1000);
           //Fourth transition
         setTimeout(() => {
          this.cursoricon.nativeElement.className = 'fourthTrans1';
          this.el.nativeElement.querySelector('.fourthTrans1').scrollIntoView();
          setTimeout(() => {
            this.cursoricon.nativeElement.style.animation = "ripple-new3 1s ease";
            this.RepeatClick();
          }, 1000);
        }, 2500);
        }, 2500);
      }, 2500);
    }, 2500);
  }

  showSkipCloseBtn = false; //skip close
  showNextRepeatBtn = false; //next repeat
  closeTour(){
    this._psychometricService.showOverlayInterest = false;
    this.showSkipCloseBtn = false;
    this.showNextRepeatBtn = false;
    var value1 = {
      startTimer : true
    }
    this._psychometricService.startTimerData.next(value1);
    this.clearTimer();

  }

  resteData(){
    this.showSkipCloseBtn = false;
    this.changeImg = false;
    this.changeImg1 = false;
    this.changeImg2 = false;
    this.changeImg3 = false;
    this.changeImg4 = false;
    this.changeImg5 = false;
    this.changeImg6 = false;

  }

  clearTimer(){
    window.clearTimeout(this.timoutVar1);
    window.clearTimeout(this.timoutVar2);
    window.clearTimeout(this.timoutVar3);
    window.clearTimeout(this.timoutVar4);
    window.clearTimeout(this.timoutVar5);
    window.clearTimeout(this.timoutVar6);
    window.clearTimeout(this.timoutVar7);
    window.clearTimeout(this.timoutVar8);
  }

  NextClick(){
    if(this.isSmallDevice){
      this.RepeatClick();
    }
    this.clearTimer();
    this.startTour1();
  }

  RepeatClick(){
    this.clearTimer();
    this.resteData();
    this.startTour();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }
}
